// src/ExpandableCard.js
import React, { useState } from 'react';
import './ExpandableCard.css'; // Import CSS for styling
import { Card, CardContent, CardHeader, IconButton, Link, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const ExpandableCard = ({ title, content,type }) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };
  
    return (
      <Card className={`card ${isExpanded ? 'expanded' : ''}`}>
        <CardHeader
          title={<Typography >{title}</Typography>}
          action={
            <IconButton onClick={toggleExpand}>
              {isExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          }
          onClick={toggleExpand}
          className="card-header"
        />
        {isExpanded && (
          <CardContent>
            <Typography>{type}</Typography>
            <Typography> <Link href={content} target="_blank" rel="noopener noreferrer">{content}</Link></Typography>
          </CardContent>
        )}
      </Card>
    );
};

export default ExpandableCard;
