import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
//import Germi_Logo from "../../images/Germi-Logo.png"
import Germi from "../../Images_1/CommonSection/Footer/GERMIFooter.png"

import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GoogleIcon from '@mui/icons-material/Instagram';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PrivacyPolicy from './PrivacyPolicy';
import VisitorCounter from '../Counter/VisitorCounter';
// import TheCounter from '../Home/TheCounter';
import axios from 'axios';
import './Footer.css'
import { BASE_URL } from '../../constants';
import { Button } from '@mui/material';

function Footer() {

	const [visitorCount, setVisitorCount] = useState(null);

	useEffect(() => {
		axios.get(BASE_URL+'totalcountlist').then((data) => { console.log(data.data.data)
			setVisitorCount(data.data.data.count); }).catch(error => {
				console.error('There was an error fetching the visitor count!', error);
			});
	}, []);

	return (
		<div>
			<footer className="footer site-footer footer-style-2 footer-bg">
				{/* <div className="effect-container exp-effect">
					<div className="circle-effect"></div>
					<div className="circle-effect"></div>
					<div className="circle-effect"></div>
				</div> */}
				<div className='black-layer'>
					<div className="pbmit-footer-widget-area">
						<div className="footer-main-div">
							<div className="footer-grid-div">
								<div className="footer-div-1">
									<div className="img-position div-1-flex">
										<Link to="/">
											<img className='footer-logo' src={Germi} alt="GERMI" />
										</Link>
										<h2 className='footer-org-name'>Gujarat Energy Research <br /> and Management Institute</h2>
									</div>
								</div>
								<div className="footer-div-2">
									<div className="widget greenly_contact_widget-2">
										<h2 className="widget-title">Contact Us</h2>
										<div className="pbmit-contact-widget-lines footer-contact-flex">
											<div className="pbmit-contact-widget-line pbmit-contact-widget-phone"> 079-2327-5757</div>
											<div className="pbmit-contact-widget-line pbmit-contact-widget-email">
												information@germi.org</div>
											<Link to="https://www.google.com/maps/place/Gujarat+Energy+Research+and+Management+Institute+(GERMI)/@23.1554002,72.6637654,825m/data=!3m1!1e3!4m6!3m5!1s0x395e802d75955e53:0xb9d6b192f2a881bc!8m2!3d23.1549187!4d72.6652702!16s%2Fg%2F1262jgjfg!5m1!1e1?entry=ttu"><div className="pbmit-contact-widget-line pbmit-contact-widget-address">1st Floor, Energy Building, <br></br>PDEU Campus,<br></br>
												Raisan Village, Gandhinagar - 382426</div></Link>
										</div>
										<div className="pbmit-footer-social-area">
											<ul className="pbmit-social-links">
												{/* <li className="pbmit-social-li pbmit-social-podcast">
												<Link to="https://soundcloud.com/sowbhagya-rao-771090612" target="_blank">
													<span><i><PodcastsIcon /></i></span>
												</Link>
											</li> &nbsp; */}
												<li className="pbmit-social-li pbmit-social-facebook">
													<Link to="https://www.facebook.com/profile.php?id=100093287444014" target="_blank"><FacebookIcon />
														<span><i></i></span>
													</Link>
												</li>&nbsp;
												<li className="pbmit-social-li pbmit-social-twitter">
													<Link to="https://twitter.com/germirnd?lang=en" target="_blank"><TwitterIcon />
														<span><i className="pbmit-base-icon-twitter-square"></i></span>
													</Link>
												</li>&nbsp;
												<li className="pbmit-social-li pbmit-social-linkedin">
													<Link to="https://www.linkedin.com/in/gujarat-energy-research-and-management-institute-2162851a4/" target="_blank">
														<span><i><LinkedInIcon /></i></span>
													</Link>
												</li>&nbsp;
												<li className="pbmit-social-li pbmit-social-youtube">
													<Link to="https://www.youtube.com/channel/UCIQvWKLApGAVyQ5RrImLqlQ" target="_blank">
														<span><i><YouTubeIcon /></i></span>
													</Link>
												</li>&nbsp;
												<li className="pbmit-social-li pbmit-social-google">
													<Link to="https://www.instagram.com/germi_gujarat/" target="_blank">
														<span><i><GoogleIcon /></i></span>
													</Link>
												</li>&nbsp;
												<li className="pbmit-social-li pbmit-social-blog">
													<Link to="https://germipower.wordpress.com/author/faradayoftoday/" target="_blank">
														<span><i><NewspaperIcon /></i></span>
													</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="footer-div-3">
									<div className="widget widget_text">
										<h2 className="footer-widget-title">Quick Links</h2>
										<div className='ql-link'>
											<li><Link to="/">Home</Link></li>
										</div>
										<div className='ql-link'>
											<li><Link to="/aboutus">About Us</Link></li>
										</div>
										<div className='ql-link'>
											<li><Link to="/departments">Department</Link></li>
										</div>
										<div className="textwidget dp-pl">
											<Link to="departments/new-technology-management/5/0">New Technology Management</Link>
										</div>
										<div className="textwidget dp-pl">
											<Link to="departments/renewable-management/5/0">Renewables Management</Link>
										</div>
										<div className="textwidget dp-pl">
											<Link to="departments/petroleum-management/5/0">Petroleum Management</Link>
										</div>
										<div className="textwidget dp-pl">
											<Link to="departments/petroleum-research-management/5/0">Petroleum Research Management</Link>
										</div>
										<div className="textwidget dp-pl">
											<Link to="departments/environment-management/5/0">Environment Management</Link>
										</div>
										
										<div className='ql-link'>
											<li><Link to="/successstories/101/1/">Success Stories</Link></li>
										</div>
										{/* <div className='ql-link ql-flex'>
											<li><Link to="/technicalteams">Technical</Link> | </li>
											<li style={{ listStyle: 'none' }}><Link to="/ourhradminteams">&nbsp;HR/Admin</Link> | </li>
											<li style={{ listStyle: 'none' }}><Link to="/ourAccountsteams">&nbsp;Account</Link></li>
										</div> */}
										<div className='ql-flex'>
											<div className='ql-flex'>
												<li className='footer-team-heading'></li>
											</div>
											<div className='ql-link ql-flex'>
												<li style={{ listStyle: 'none' }}><Link to="/technicalteams">&nbsp;Technical&nbsp;</Link></li>
											</div>|
											<div className='ql-link ql-flex'>
												<li style={{ listStyle: 'none' }}><Link to="/ourhradminteams">&nbsp;HR, Admin & IT&nbsp;</Link></li>
											</div>|
											<div className='ql-link ql-flex'>
												<li style={{ listStyle: 'none' }}><Link to="/ourAccountsteams">&nbsp;Finance</Link></li>
											</div>
										</div>
										<div className='ql-link'>
											<li><Link to="/publications">Our Publications</Link></li>
										</div>
										<div className='ql-link'>
											<li><Link to="/contactus">Contact Us</Link></li>
										</div>
									</div>
								</div>
								<div className="footer-div-4">
									<div className="widget widget_text">
										<h2 className="footer-widget-title">Also Visit</h2>
										{/* <div className="ql-link">
											<li><Link to="http://www.germi.org/prw/">Petroleum Research Wing</Link></li>
										</div> */}
										{/* <div className="ql-link">
											<li><Link to="http://pvinstaller.germi.org/">National Certification Programme</Link></li>
										</div> */}
										{/* <div className="ql-link">
											<li><Link to="#">Download GERMI Brochure</Link></li>
										</div> */}
										<div className='ql-link'>
											<li><Link to="https://pdpu.ac.in/">Pandit Deendayal Energy University</Link></li>
										</div>
										<div className='ql-link'>
											<li><Link to="https://www.gspcgroup.com/">Gujarat State Petroleum Corporation</Link></li>
										</div>
										<div className="ql-link">
											<li><Link to="https://guj-epd.gujarat.gov.in/Index">Energy & Petrochemicals Department</Link></li>
										</div>
									</div>
									<div className=" pbmit-footer-menu-area">
										<ul className="pbmit-footer-menu">
											<li className="menu-item">
												<Link to="/privacypolicy">Privacy Policy</Link>
											</li>

											<li className="menu-item">
												<Link to="/sitemap">Sitemap</Link>
											</li>
											<li className="menu-item">
												<div className='menu-item-visitor'>
													<span className='counter-text-div'>Total Visitor Count:</span>
													<span>
														{/* <TheCounter className="counter-text-div" maxValue="10000" duration="1000" /> */}
														{visitorCount !== null ? visitorCount : 'Loading...'}
													</span>
												</div>

											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="pbmit-footer-text-area">
						<div className="pbmit-footer-text-inner">
							<div className="bottom-container">
								<div className="bottom-div">
									<div className="pbmit-footer-copyright-text-area pipe-class">Copyright © {new Date().getFullYear()}  GERMI, All Rights Reserved</div>
									<div className="pbmit-footer-copyright-text-area">Developed by  <button className='linkfooter' onClick={()=>window.open("https://codewing.tech/")}>Codewing Technology Pvt. Ltd.</button></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer >
		</div >
	)
}

export default Footer