import React, { useState, useEffect, useRef } from 'react'
import '../../css/bootstrap.min.css'
import '../../css/fontawesome.css'
import '../../css/flaticon.css'
import '../../css/pbminfotech-base-icons.css'
import '../../css/swiper.min.css'
import '../../css/magnific-popup.css'
import '../../css/shortcode.css'
import '../../css/demo-3.css'
import '../../css/base.css'
import '../../css/style.css'
import '../../css/responsive.css'
import HomeSlider from './HomeSlider/HomeSlider'
import HomeAboutUs from './HomeAboutUs/HomeAboutUs'
import SuccessNumbers from './Individual/SuccessNumbers'
import HomeNotices from './HomeNotices/HomeNotices.js'
import NewSuccessStories from './SuccessStories/NewSuccessStories/NewSuccessStories.js'
import Choose from './Choose/Choose.js'
import Clients from './Clients/Clients.js'
import './Home.css'
import HomeCounter from './HomeCounter/HomeCounter'
import { useDispatch, useSelector } from 'react-redux'
import { Updates } from './store/UpdateDataSlice.js'
import { Helmet } from 'react-helmet'
import Home3ServicesNew from './HomeServicesNew/Home3ServicesNew.js'
import { PopupData } from './store/PopupDataSlice.js'
import { BASE_URL_Image } from '../../constants.js'

var imagepopup="";
var titlepopup="";
var despopup="";
var linkpopup="";
const Popup = ({ onClose }) => {
    const popupRef = useRef(null);

  useEffect(() => {
    // Handle clicks outside of the popup
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose]);
    return (
      <div className='popupoverlay'>
        <div className='div-card-container' ref={popupRef}>

        <button onClick={onClose} className='close-button'>
          &times;
        </button>

          {/* Image Section */}
          <div className='Section1'>
            <img
              src={imagepopup} // Replace with your image URL
              alt="Popup"
             className='popupimage'
            />
          </div>
  
          {/* Text Section */}
          <div className='Section2'>
            <h4>{titlepopup}</h4>
            <td dangerouslySetInnerHTML={{__html: despopup}} />
            <button onClick={()=>window.open(linkpopup)}>View Detail</button>
          </div>
        </div>
      </div>
    );
};



function Homepage() {

    const [showPopup, setShowPopup] = useState(false);
    const topWrapperRef1 = useRef(null);
    useEffect(() => {
      dispatch(PopupData()).then((res) => {

        if(res.payload.data[0].status=="1"){
        imagepopup=BASE_URL_Image + res.payload.data[0].image;
        titlepopup=res.payload.data[0].title;
        despopup=res.payload.data[0].description;
        linkpopup=res.payload.data[0].link;
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 5000);
      return () => clearTimeout(timer);
    }
    })
    }, []);
  
    const closePopup = () => {
      setShowPopup(false);
    };
    const instagramPostUrl = 'https://www.instagram.com/p/CGXASWKjlMa/'; // Replace with your Instagram post URL



    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(Updates());
        dispatch(PopupData());
    }, []);
    const updates = useSelector((state) => state.updatesdata);
    const popupData = useSelector((state) => state.popupData);

    console.log("popupData")
    console.log(popupData)

    const updatesData = updates?.data?.data;

    return (
        <>
         {showPopup && <Popup onClose={closePopup} />}
            <Helmet>
                <title>GERMI</title>
                <title hidden>Gujarat Energy Research & Management Institute</title>
            </Helmet>
            
            {/* <PropagateLoader
                color={"#7cba27"}
                loading={loading}
                size={30}
                className='loader-center'
                aria-label="Loading Spinner"
                data-testid="loader"
                style={loading ? {
                    display: "flex"
                } : { display: "none" }}
            />*/}
            <div >
                <div className="page-wrapper pbmit-bg-color-white">
                    <div className="page-content">

                        {/* Home Slider Start */}

                        <HomeSlider data={updatesData} topWrapperRef1={topWrapperRef1} />

                        {/* Home Slider End */}

                        {/* About Us Start */}
                        <section>
                            <HomeAboutUs />
                        </section>
                        {/* About Us End */}

                        {/* Success Number Start */}
                        {/* <section>
                        <SuccessNumbers />
                    </section> */}

                        <section>
                            <HomeCounter />
                        </section>
                        {/* Success Number End */}


                        {/* Success Stories End */}

                        {/* <section >
                            <HomeServicesNew />
                        </section> */}
                        <section >
                            <Home3ServicesNew ref={topWrapperRef1} />
                        </section>

                        {/* Success Stories Start */}
                        <section className='ns-section'>
                            <NewSuccessStories />
                        </section>
                        {/* Services End */}

                        {/* Notice Card Start */}
                        <section>
                            <HomeNotices />
                        </section>
                        {/* Notice Card End */}

                        {/* Choose Start */}
                        <section>
                            <Choose />
                        </section>
                        {/* Choose End */}

                        {/* Social Media Start */}
                        {/* <SocialMedia /> */}
                        {/*Social Media End */}    

                        {/* Clients Start */}
                        {/* <section className='clients-bg-div'>
                            <div className="container">
                                <div className=" text-center">
                                    <div className='pt-5'>
                                    <OnScrollAnimation title1="oc1" title2="oc2" hr1="oc3" hr2="oc4" heading="Our Clients" subheading="" />
                                    </div>

                                </div>
                                <Clients />
                            </div>
                        </section> */}
                        {/* Clients End */}


                    </div>
                </div>


            </div>
        </>
    )
}

export default Homepage;
