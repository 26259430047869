import React, { useEffect, useState } from "react";
import "./NewsListView.css";
import BreadCrumbImage from '../../../../Images_1/CommonSection/Braedcrumbs/NAM.jpg'
import BreadCrumbs from "../../../BreadCrumbs/BreadCrumbs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ScrollToTop from "../../../ScrollToTop";

// import announcement1 from '../../../../images/announcementImage.jpg'
// import announcement2 from '../../../../images/announcementImage2.jpg'
// import news1 from '../../../../images/newsImage.png'
// import tenderImage from '../../../../images/tenderImage2.jpg'
import { useDispatch, useSelector } from "react-redux";
import { News } from "../../../noticeCards/store/NewsDataSlice";
import { BASE_URL_Image } from '../../../../constants';

const NewsListView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 4;
  const totalItems = 12;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = [1, 2, 3, 4]; // Replace with your actual data for pagination
  useEffect(() => {

    dispatch(News());

  }, []);
  const newsdata = useSelector((state) => state.newsdata);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleNavigate = (data) => () => {
    // console.log(data);
    // navigate("/announcementdetails");
    navigate("/announcementdetails/" + data[0], { state: { id: data[0], name: data[1] } });
  };

  return (
    <>
      <ScrollToTop />
      <BreadCrumbs parent="Home" child="News" parentPath="/" imgPath={BreadCrumbImage} />

      <div className="mainambg">
        {/* <div className="heading-container">
          <h4>News Details</h4>
          <div className='title-animation-about-us'>Explore our newest announcements, stay informed with the latest news, and seize exciting tender opportunities now available.</div>
        </div> */}

        <div className="notices-container relative">


          <div className="grid grid-cols-12 max-md:gap-y-25 md:gap-5 lg:gap-8">

            <div className="max-md:col-span-full max-lg:col-span-7 max-md:order-2 lg:col-span-8 [&>*:not(:last-child)]:mb-8">
              {/* <article className="bg-white dark:bg-dark-200 rounded-medium p-2.5 shadow-nav">

    <div className="border border-dashed rounded border-gray-100 dark:border-borderColour-dark p-6 max-md:p-4">

      <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-12 items-center">


                  <img className="w-full rounded-lg" src={announcement1} />
      

                <div class>
                  <div className="List-Card-Heading">
                    <div className="tag-container">
                      <button>Announcement</button>
                    </div>
                    <h1 className="List-Card-Heading-text">
                      5-day Training Program on "Green Hydrogen PtX - Electrolyser and Fuel Cell Demonstration".
                    </h1>
                    <div className="TimeStamp">
                      <p>Cost- Free</p>
                      <p>Duration - 5 Days</p>
                    </div>
                  </div>
                  <div className="List-Card-text">
                   
                      GERMI is organizing two batches of training program on
                      "Green Hydrogen PtX - Electrolyser and Fuel Cell Demonstration".
                      The details of both the training programs are given below.
                   
                  </div>
                  <div className="List-Card-button">
                    <button onClick={handleNavigate([1, "News"])}>Read More</button>
                  </div>
                </div>

      </div>
    </div>
  </article> */}

              {newsdata.data && newsdata.data.map((data) =>
                <article className="bg-white dark:bg-dark-200 rounded-medium p-2.5 shadow-nav">

                  <div className="border border-dashed rounded border-gray-100 dark:border-borderColour-dark p-6 max-md:p-4">

                    <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-12 items-center">


                      <img className="w-full rounded-lg" src={BASE_URL_Image + data.file} />


                      <div class>
                        <div className="List-Card-Heading">
                          <div className="tag-container static-small-text">
                            <button>News</button>
                          </div>
                          <h1 className="List-Card-Heading-text subheading-fonts">
                            {data.title}
                          </h1>
                          <div className="TimeStamp">
                            {/* <p>Mar 4 2024</p> */}
                          </div>
                        </div>
                        <div className="List-Card-text normal-text">
                        {data.shortdes}
                          {/* Dr. Anirbid Sircar, Director General, GERMI and Dr. A. K. Tripathy, Director General,
                      NISE ... */}
                        </div>
                        <div className='see-more-text-card'>
                          <button
                            onClick={()=>{data.filepdf!=""?
                              window.open(BASE_URL_Image + data.filepdf)
                              :
                              window.open(data.reglink)}
                              } 
                            // onClick={handleNavigate(data)}
                            className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              )}



              {/* <article className="bg-white dark:bg-dark-200 rounded-medium p-2.5 shadow-nav">

<div className="border border-dashed rounded border-gray-100 dark:border-borderColour-dark p-6 max-md:p-4">

  <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-12 items-center">


              <img className="w-full rounded-lg" src={announcement2} />
  

            <div class>
            <div className="List-Card-Heading">
                    <div className="tag-container">
                      <button>Announcement</button>
                    </div>
                    <h1 className="List-Card-Heading-text">
                      5-day Training Program on "Green Hydrogen PtX - Electrolyser and Fuel Cell Installation"
                    </h1>
                    <div className="TimeStamp">
                      <p>Cost - Free</p>
                      <p>Duration - 5 Days</p>
                    </div>
                  </div>
                  <div className="List-Card-text">
                   
                      The training will be conducted in a 'participative mode'
                      wherein each session builds upon the previous.
                      Topics covered during the training duration are as follows
              
                  </div>
                  <div className="List-Card-button">
                    <button onClick={handleNavigate([1, "News"])}>Read More</button>
                  </div>
            </div>

  </div>
</div>
</article> */}


              {/* <article className="bg-white dark:bg-dark-200 rounded-medium p-2.5 shadow-nav">

<div className="border border-dashed rounded border-gray-100 dark:border-borderColour-dark p-6 max-md:p-4">

  <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-12 items-center">


              <img className="w-full rounded-lg" src={tenderImage} />
  

            <div class>
            <div className="List-Card-Heading">
                    <div className="tag-container">
                      <button>Tender</button>
                    </div>
                    <h1 className="List-Card-Heading-text">
                      BSPGCL EPC Tender for the project
                    </h1>
                    <div className="TimeStamp">
                     
                      <p>Nov 21 2023</p>
                    </div>
                  </div>
                  <div className="List-Card-text">
                  
                      Tender is to be invited for EPC and O&M (for 10 years) from a
                      single Contractor for 185 MW (AC) plant capacity along with
                      45.4 MW for 4 hours (Min. 254 MWh) BESS.
                    
                  </div>
                  <div className="List-Card-button">
                    <button onClick={handleNavigate([1, "News"])}>Read More</button>
                  </div>
            </div>

  </div>
</div>
</article> */}


              {/* <article className="bg-white dark:bg-dark-200 rounded-medium p-2.5 shadow-nav">

<div className="border border-dashed rounded border-gray-100 dark:border-borderColour-dark p-6 max-md:p-4">

  <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-12 items-center">


              <img className="w-full rounded-lg" src={tenderImage} />
  

            <div class>
            <div className="List-Card-Heading">
                    <div className="tag-container">
                      <button>Tender</button>
                    </div>
                    <h1 className="List-Card-Heading-text">
                      BSPGCL EPC Tender for the project
                    </h1>
                    <div className="TimeStamp">
                     
                      <p>Nov 21 2023</p>
                    </div>
                  </div>
                  <div className="List-Card-text">
                    <p>
                      Tender is to be invited for EPC and O&M (for 10 years) from a
                      single Contractor for 185 MW (AC) plant capacity along with
                      45.4 MW for 4 hours (Min. 254 MWh) BESS.
                    </p>
                  </div>
                  <div className="List-Card-button">
                    <button onClick={handleNavigate([1, "News"])}>Read More</button>
                  </div>
            </div>

  </div>
</div>
</article> */}


            </div>

            {/* <div className="max-md:col-span-full max-lg:col-span-5 max-md:order-1 lg:col-span-4 self-start ">
  <div className="bg-white dark:bg-dark-200 rounded-medium p-2.5  shadow-nav">
    <div className="bg-white dark:bg-dark-200 border border-dashed rounded border-gray-100 dark:border-borderColour-dark py-12 px-8">
  <div className="List-Grid-Card-Categories">
                <div>
                  <h1>Categories</h1>
                  <ul>
                    <li>
                      <a>Announcement</a>
                    </li>
                    <li>
                      <a>News</a>
                    </li>
                    <li>
                      <a>Tender</a>
                    </li>
                  </ul>
                </div>
                <div className="Tags">
                  <div>
                    <h1>Tags</h1>
                  </div>
                  <div className="button-container">
                    <button>Energy</button>
                    <button>Solar</button>
                    <button>Windmill</button>
                  </div>
                </div>
              </div>
              </div>
  </div>
</div> */}



          </div>





        </div>







        {/* <div className="List-Grid-Container pb-announcement">
          <div className="Card-Container">
           
            <div className="Card-holder">
              <div className="List-Grid-Card-Container">
                <div className="List-Card-Image">
                  <img src={announcement1} />
                </div>
                <div className="List-Card-Content">
                  <div className="List-Card-Heading">
                    <div className="tag-container">
                      <button>Announcement</button>
                    </div>
                    <h1 className="List-Card-Heading-text">
                      5-day Training Program on "Green Hydrogen PtX - Electrolyser and Fuel Cell Demonstration".
                    </h1>
                    <div className="TimeStamp">
                      <p>Cost- Free</p>
                      <p>Duration - 5 Days</p>
                    </div>
                  </div>
                  <div className="List-Card-text">
                    <p>
                      GERMI is organizing two batches of training program on
                      "Green Hydrogen PtX - Electrolyser and Fuel Cell Demonstration".
                      The details of both the training programs are given below.
                    </p>
                  </div>
                  <div className="List-Card-button">
                    <button onClick={handleNavigate([1, "News"])}>Read More</button>
                  </div>
                </div>
              </div>
            </div>

           
            <div className="Card-holder">
              <div className="List-Grid-Card-Container">
                <div className="List-Card-Image">
                  <img src={news1} />
                </div>
                <div className="List-Card-Content">
                  <div className="List-Card-Heading">
                    <div className="tag-container">
                      <button>News</button>
                    </div>
                    <h1 className="List-Card-Heading-text">
                      MoU signed between Gujarat Energy Research and Management Institute (GERMI)
                      and National Institute of Solar Energy (NISE) For Association in the field
                      of Renewable Energy.
                    </h1>
                    <div className="TimeStamp">
                    
                      <p>Mar 4 2024</p>
                    </div>
                  </div>
                  <div className="List-Card-text">
                    <p>
                      Dr. Anirbid Sircar, Director General, GERMI and Dr. A. K. Tripathy, Director General,
                      NISE ...
                    </p>
                  </div>
                  <div className="List-Card-button">
                    <button onClick={handleNavigate([1, "News"])}>Read More</button>
                  </div>
                </div>
              </div>
            </div>

           
            <div className="Card-holder">
              <div className="List-Grid-Card-Container">
                <div className="List-Card-Image">
                  <img src={announcement2} />
                </div>
                <div className="List-Card-Content">
                  <div className="List-Card-Heading">
                    <div className="tag-container">
                      <button>Announcement</button>
                    </div>
                    <h1 className="List-Card-Heading-text">
                      5-day Training Program on "Green Hydrogen PtX - Electrolyser and Fuel Cell Installation"
                    </h1>
                    <div className="TimeStamp">
                      <p>Cost - Free</p>
                      <p>Duration - 5 Days</p>
                    </div>
                  </div>
                  <div className="List-Card-text">
                    <p>
                      The training will be conducted in a 'participative mode'
                      wherein each session builds upon the previous.
                      Topics covered during the training duration are as follows
                    </p>
                  </div>
                  <div className="List-Card-button">
                    <button onClick={handleNavigate([1, "News"])}>Read More</button>
                  </div>
                </div>
              </div>
            </div>

            
            <div className="Card-holder">
              <div className="List-Grid-Card-Container">
                <div className="List-Card-Image">
                  <img src={tenderImage} />
                </div>
                <div className="List-Card-Content">
                  <div className="List-Card-Heading">
                    <div className="tag-container">
                      <button>Tender</button>
                    </div>
                    <h1 className="List-Card-Heading-text">
                      BSPGCL EPC Tender for the project
                    </h1>
                    <div className="TimeStamp">
                     
                      <p>Nov 21 2023</p>
                    </div>
                  </div>
                  <div className="List-Card-text">
                    <p>
                      Tender is to be invited for EPC and O&M (for 10 years) from a
                      single Contractor for 185 MW (AC) plant capacity along with
                      45.4 MW for 4 hours (Min. 254 MWh) BESS.
                    </p>
                  </div>
                  <div className="List-Card-button">
                    <button onClick={handleNavigate([1, "News"])}>Read More</button>
                  </div>
                </div>
              </div>
            </div>

            
            <div className="Card-holder">
              <div className="List-Grid-Card-Container">
                <div className="List-Card-Image">
                  <img src={tenderImage} />
                </div>
                <div className="List-Card-Content">
                  <div className="List-Card-Heading">
                    <div className="tag-container">
                      <button>Tender</button>
                    </div>
                    <h1 className="List-Card-Heading-text">
                      BSPGCL EPC Tender for the project
                    </h1>
                    <div className="TimeStamp">
                     
                      <p>Nov 21 2023</p>
                    </div>
                  </div>
                  <div className="List-Card-text">
                    <p>
                      Tender is to be invited for EPC and O&M (for 10 years) from a
                      single Contractor for 185 MW (AC) plant capacity along with
                      45.4 MW for 4 hours (Min. 254 MWh) BESS.
                    </p>
                  </div>
                  <div className="List-Card-button">
                    <button onClick={handleNavigate([1, "News"])}>Read More</button>
                  </div>
                </div>
              </div>
            </div>

           
            <div className="Card-holder">
              <div className="List-Grid-Card-Container">
                <div className="List-Card-Image">
                  <img src={tenderImage} />
                </div>
                <div className="List-Card-Content">
                  <div className="List-Card-Heading">
                    <div className="tag-container">
                      <button>Tender</button>
                    </div>
                    <h1 className="List-Card-Heading-text">
                      BSPGCL EPC Tender for the project
                    </h1>
                    <div className="TimeStamp">
                      
                      <p>Nov 21 2023</p>
                    </div>
                  </div>
                  <div className="List-Card-text">
                    <p>
                      Tender is to be invited for EPC and O&M (for 10 years) from a
                      single Contractor for 185 MW (AC) plant capacity along with
                      45.4 MW for 4 hours (Min. 254 MWh) BESS.
                    </p>
                  </div>
                  <div className="List-Card-button">
                    <button onClick={handleNavigate([1, "News"])}>Read More</button>
                  </div>
                </div>
              </div>
            </div>

           
            <div className="pagination">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                &lt;-
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={currentPage === i + 1 ? "active" : ""}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                -&gt;
              </button>
            </div>
          </div>

         
          <div className="List-Grid-Categories-Container">
            <div className="Categories-Container">
             
              <div className="List-Grid-Card-Categories">
                <div>
                  <h1>Categories</h1>
                  <ul>
                    <li>
                      <a>Announcement</a>
                    </li>
                    <li>
                      <a>News</a>
                    </li>
                    <li>
                      <a>Tender</a>
                    </li>
                  </ul>
                </div>
                <div className="Tags">
                  <div>
                    <h1>Tags</h1>
                  </div>
                  <div className="button-container">
                    <button>Energy</button>
                    <button>Solar</button>
                    <button>Windmill</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      </div>
    </>
  );
};

export default NewsListView;
