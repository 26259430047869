import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import germi from "../../Images_1/CommonSection/Header/GERMI_Logo.png"
import socialmedia from "../../Images_1/CommonSection/Header/socialmedia.svg"
import skipicon from "../../Images_1/CommonSection/Header/skipicon.png"
import g20 from "../../Images_1/CommonSection/Header/G20_Logo.png"
import './Header.css'
import Navbar from './Navbar'
import FontAdjuster from '../../FontAdjuster'
import { FontSizeProvider } from '../../FontSizeContext'
import { useTheme } from '../../ThemeContext'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import EvStationIcon from '@mui/icons-material/EvStation';
function Header() {
	//navbar-toggler
	//aria-expanded="true"
	//navbar-collapse collapse show
	const [activeBtn, setActiveBtn] = useState('');
	const [c1Btn, setc1Btn] = useState('navbar-toggler collapsed');
	const [c2Btn, setc2Btn] = useState(false);
	const [c3Btn, setc3Btn] = useState('navbar-collapse collapse');
	const [isOpen, setIsOpen] = useState([false, false, false, false, false, false]);
	const headerStyle = {
		color: "var(--pbmit-global-color)"
	}

	const [width, setWidth] = useState(window.innerWidth);
    window.onresize = function () {
        setWidth(window.innerWidth);
    }
	const handleIsOpen = (index) => {
		const newOpen = isOpen.map((item, i) => {
			if (i == index) {
				return !item;
			}
			return item;
		}
		);
		setIsOpen(newOpen);
	}
	const { isNightMode } = useTheme();
	return (
		<>
			<FontSizeProvider>
				<div className='fixed-header-class'>
					<div className="gujaratHeadingheader" id="top-nav">
						{/* <FontAdjuster /> */}
						<span className="headingtitle">Promoted by GSPC (Govt. Of Gujarat Undertaking) </span>
						{/* <Link className='linkheader' to={"/ScreenReaderAccess"}> |&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Screen Reader Access</Link> */}
						{/* <Link className='linkheader' to={"/ScreenReaderAccess"}> | Screen Reader Access</Link> */}
					</div>
					<div className="gujaratHeadingheaderright" id="top-nav">
						{/* <FontAdjuster /> */}
						{/* <span className="headingtitle">Promoted by GSPC (Govt. Of Gujarat Undertaking) </span> */}
						{/* <Link className='linkheaderright' onClick={() => console.log("/socialmedia")}><img className="social-logo" src={skipicon} alt="GERMI" />Skip to main Content  | </Link> */}
						{/* <Link className='linkheaderright' to={"#"}><img className="social-logo" src={skipicon} alt="GERMI" />Skip to main Content  | </Link> */}
						<Link className='linkheaderright' to={"/socialmedia"}><img className="social-logo" src={socialmedia} alt="GERMI" />Social Media   | </Link>
						<Link className='linkheaderright' to={"/solarcalculator"}><SolarPowerIcon></SolarPowerIcon>Solar Calculator | </Link>
						<Link className='linkheaderright' to={"/evcalculator"}><EvStationIcon></EvStationIcon> EV Calculator</Link>
					</div>

					<header className="site-header header-style-6">
				
						<div className="site-header-menu">
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-12">
										<div className="d-flex align-items-center justify-content-between">
											<div className="site-branding">
												<Link to="/">
													<img className="logo-img" src={germi} alt="GERMI" />
												</Link>
											</div>


											{width > 500?<Navbar /> : 

											<div className="site-branding">
												<Link to="/">
													<img className="logo-img" src={g20} alt="GERMI" />
												</Link>
											</div>

											}

											{
												width > 500? <div className="site-branding">
												<Link to="/">
													<img className="logo-img" src={g20} alt="GERMI" />
												</Link>
											</div> :
											 <Navbar />
											}

										</div>
									</div>
								</div>
							</div>
						</div>
					</header>
				</div>
			</FontSizeProvider>
		</>
	)
}

export default Header
