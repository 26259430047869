import React from 'react'
import './JobDetails.css'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
import BreadCrumbImage from '../../Images_1/CommonSection/Braedcrumbs/gallery.jpg';
import { useLocation, Link } from 'react-router-dom';
import dayjs from 'dayjs';
const JobDetails = () => {
    const location = useLocation();
    // console.log(location.state)
    const data = location.state;
    return (
        <div>
            {/* Title Bar */}
            <BreadCrumbs parent="Home" child="Job Details" parentPath="/" imgPath={BreadCrumbImage} />
            <section className="job-main-section">
                <div>
                    <div className="row jd-main-div pt-4">
                        <div className="jd-left-div ">
                            <h2 className='large-heading-fonts'>{data.positionname}</h2>
                            <h4 className='medium-heading-fonts'>Minimum Qualification and Experience</h4>
                            <span className = "normal-text" dangerouslySetInnerHTML={{ __html: data.minqualification }}></span>

                            <div className="responsibilty my-3 mt-5">
                                <h4 className='medium-heading-fonts'>Job Responsibilities</h4>
                                <span className = "normal-text" dangerouslySetInnerHTML={{ __html: data.jobdescription }}></span>
                                {/* <ul>
                                    <li>Be involved in every step of the product design cycle from discovery and user acceptance testing.</li>
                                    <li>Work with BAs, product managers and tech teams to lead the Product Design</li>
                                    <li>Maintain quality of the design process and ensure that when designs are translated into code they accurately.</li>
                                    <li>Accurately estimate design tickets during planning sessions.</li>
                                    <li>Contribute to sketching sessions involving non-designersCreate, and pattern libraries.</li>
                                    <li>Design pixel perfect responsive UI’s and understand that adopting common interface.</li>
                                    <li>Interface patterns is better for UX than reinventing the wheel</li>
                                </ul> */}
                            </div>

                            {/* <div className="requirement my-3 mt-5">
                                <h4>Job Skills</h4>
                            </div> */}

                            <div className="skills my-3 mt-5">
                                <h4 className='medium-heading-fonts'>Skill & Experience</h4>
                                <span className = "normal-text" dangerouslySetInnerHTML={{ __html: data.jobskill }} />

                            </div>

                            <div className="my-3 mt-5">
                                <h4 className='medium-heading-fonts'>Notes</h4>
                                <span className = "normal-text-blue" dangerouslySetInnerHTML={{ __html: data.notes }} />

                            </div>
                        </div>
                        <div className="jd-right-div ">
                            <div className="card-body jd-sticky-sidebar">
                                <h4 className="jb-card-title my-3 twenty-sixteen-fonts">Job Overviews</h4>
                                <div className='jb-overview-detail'>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                    </svg> */}
                                    <div>
                                        <span className='jb-card-title-h6 normal-text'>Name of Position :</span>
                                        <span className='jb-card-title-p static-normal-text'>{data.positionname}</span>
                                    </div>

                                </div>
                                <div className='jb-overview-detail'>
                                    <div>
                                        <span className='jb-card-title-h6 normal-text'>No. of Position :</span>
                                        <span className='jb-card-title-p static-normal-text'>{data.noofposition}</span>
                                    </div>

                                </div>
                                <div className='jb-overview-detail'>
                                    <div>
                                        <span className='jb-card-title-h6 normal-text'>Type of Position :</span>
                                        <span className='jb-card-title-p static-normal-text'>{data.positiontype}</span>
                                    </div>

                                </div>
                                <div className='jb-overview-detail'>
                                    <div>
                                        <span className='jb-card-title-h6 normal-text'>Age :</span>
                                        <span className='jb-card-title-p static-normal-text'>{data.positionage}</span>
                                    </div>

                                </div>
                                <div className='jb-overview-detail'>
                                    <div>
                                        <span className='jb-card-title-h6 normal-text'>Reporting to :</span>
                                        <span className='jb-card-title-p static-normal-text'>{data.reportto}</span>
                                    </div>

                                </div>
                                {/* <span className='jb-card-title-h6'></span> */}

                                <div className='jb-overview-detail'>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                                            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                                        </svg> */}
                                    <div>
                                        <span className='jb-card-title-h6 normal-text'>Remuneration :</span>
                                        <span className='jb-card-title-p static-normal-text'>{data.positionremuneration}</span>
                                    </div>

                                </div>
                                <div className='jb-overview-detail'>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                                    </svg> */}
                                    <div>
                                        <span className='jb-card-title-h6 normal-text'>Location :</span>
                                        <span className='jb-card-title-p static-normal-text'>{data.location}</span>
                                    </div>

                                </div>
                                <div className='jb-overview-detail'>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                                    </svg> */}
                                    <div>
                                        <span className='jb-card-title-p normal-text-red'>{"(Last date of application: "+dayjs(data.lastdate).format("DD/MM/YYYY")+" till "+dayjs(data.lasttime).format("hh:mm A")+" )"+" "}</span>
                                        {/* <span className='jb-card-title-p static-normal-text'>{data.location}</span> */}
                                    </div>

                                </div>
                                {/* <Link to="/applicationFormNew/" state={data}><button className='btn btn-primary mb-3'><span className='static-small-text'>Apply Now</span></button></Link> */}
                                <Link to={"/applicationFormNew/"+data.id+"/"+data.positionname+"/"+data.maxage+"/"+data.positioncode+"/"+data.dateason+"/"+data.minexp+"/"+data.startdate} state={data}><button className='btn btn-primary mb-3'>Apply Now</button></Link>
                                {/* <h6 className='jb-card-title-h6'>Location : </h6>
                                <span className='jb-card-title-p'>London, UK</span>
                                <h6 className='jb-card-title-h6'>Salary : </h6>
                                <span className='jb-card-title-p'>$35k - $40k</span> */}
                               
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='btnapp'><Link to={"/applicationFormNew/"+data.id+"/"+data.positionname+"/"+data.maxage+"/"+data.positioncode } state={data}><button className='btn btn-primary mb-3'>Apply Now</button></Link></div> */}
            </section>
        </div>
    )
}

export default JobDetails
