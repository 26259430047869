import React from 'react'
import Header from './CommonSection/Header'
import Footer from './CommonSection/Footer'
import { Link, Outlet } from 'react-router-dom'
import ScrollButton from './Home2/ScrollButton/ScrollButton'
import SideMenuButton from './Home2/SideMenuButton/SideMenuButton'
// import { useTheme } from '../ThemeContext'
import { FontSizeProvider } from '../FontSizeContext'
import { ThemeProvider } from '../ThemeContext'
// import Home from './components/Home/Home'
// import AboutUs_2 from './Pages/AboutUs_2'
// import AboutUs1 from './Pages/AboutUs1'
// import Faq from './Pages/Faq'
// import TeamSingle from './Pages/TeamSingle'
// import Services from './Services/Services'
import MainLayout from './MainLayout/MainLayout'

function Layout() {
  return (
    <>
      <FontSizeProvider>
        <ThemeProvider>
          <MainLayout />
          <SideMenuButton />
         
          <ScrollButton></ScrollButton>
        </ThemeProvider>

      </FontSizeProvider>
    </>
  )
}

export default Layout
