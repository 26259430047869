import { configureStore } from '@reduxjs/toolkit';
import AnnouncementReducer from "./AnnouncementDataSlice.js"
import AnnouncementById from "./getAnnouncementByIdSlice.js"
import NewsReducer from "./NewsDataSlice.js"
import TenderReducer from "./TenderDataSlice.js"
import CareersData from '../../Carrers/store/CareersDataSlice.js';
import AddApplication from '../../Form3/store/AddApplication.js';
import UpdateData from "../../Home2/store/UpdateDataSlice.js"
import AddDonation from '../../Give/store/AddDonate.js';
import AddSampleAnalysis from '../../SampleAnalysisForm/store/AddSampleAnalysis.js';
import DptAnnouncementDataSlice from './DptAnnouncementDataSlice.js';
import { sendfeedbackcall } from '../../AddApplicationForm/store/SendFeedback.js';
export const store = configureStore({
    reducer: {
        announcementdata: AnnouncementReducer,
        AnnouncementById: AnnouncementById,
        newsdata: NewsReducer,
        tenderdata: TenderReducer,
        careerdata: CareersData,
        addapplicationdata: AddApplication,
        sendfeedbackdata: sendfeedbackcall,
        updatesdata: UpdateData,
        addgivedata: AddDonation,
        addeminquiry: AddSampleAnalysis,
        addeminquiry: AddSampleAnalysis,
        dptannouncementdata:DptAnnouncementDataSlice
    }
})