import React, { forwardRef } from 'react'
import './Home3ServicesNew.css'
import { Link } from 'react-router-dom'
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import AboutImage1 from '../../../Images_1/Home/ServicesImages/sv1.png'
import AboutImage2 from '../../../Images_1/Home/ServicesImages/sv2.png'
import AboutImage3 from '../../../Images_1/Home/ServicesImages/sv3.png'
import AboutImage4 from '../../../Images_1/Home/AboutImages/EM.png'
import AboutImage5 from '../../../Images_1/Home/AboutImages/PM.png'
import OnScrollAnimation from '../../OnScrollAnimation';
import Clients from '../Clients/Clients';

const Home3ServicesNew = forwardRef((props, ref) => {
    return (
        <>
          
            <section ref={ref} className='bg'>
              
                <div className='heading-main'>
                <OnScrollAnimation title1="services1" title2="services2" hr1="services3" hr2="services4" heading="Our Services" subheading="GERMI offers comprehensive services including expert Advisory & Consulting, cutting-edge Research & Development, and tailored Training & Skill Development across our five specialized departments in Energy Sector" />
                </div>
                <section className='home3-services-section consulting-bg'>
                    <div className='bg-dark-div4'>
                    <div className='bg-dark-div3'>
                        <div className='home3-services-div-container'>
                            <div className='home3-services-div'>
                            <div className='home3-services-depts-grid grid-3-33'>
                                            <div className='home3-services-depts-div'>
                                                <Link className='home3-services-depts-font small-text' to='ourservices/1'>
                                                    <div className='home3-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div3">
                                                                <img src={AboutImage1} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center"> Advisory & Consulting</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home3-services-depts-div'>
                                                <Link className='home3-services-depts-font small-text' to='ourservices/2'>
                                                    <div className='home3-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div3">
                                                                <img src={AboutImage2} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">Research & Development</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='home3-services-depts-div'>
                                                <Link className='home3-services-depts-font small-text' to='ourservices/3'>
                                                    <div className='home3-services-depts'>
                                                        <div>
                                                            <div className="service-icon-div3">
                                                                <img src={AboutImage3} className="service-icon" alt="dept-1" />
                                                            </div>
                                                        </div>
                                                        <div className="service-text-center">Training & Skill Development</div>
                                                    </div>
                                                </Link>
                                            </div>
                                           
                                           
                                          
                                        </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>
             
             
            </section>
        </>
    )
});

export default Home3ServicesNew;
