import React, { useEffect } from 'react'
import { useState } from 'react';
import './SideMenuButton.css'
import { Link } from 'react-router-dom';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ContrastIcon from '@mui/icons-material/Contrast';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import { FontSizeProvider, useFontSize } from '../../../FontSizeContext';
import increaseIcon from '../../../Images_1/Home/headericon/increase-font.png'

import resetIcon from '../../../Images_1/Home/headericon/reset-font.png'
import decreaseIcon from '../../../Images_1/Home/headericon/decrease-font.png'
import { useTheme } from '../../../ThemeContext';

function SideMenuButton() {
    const { isNightMode, toggleTheme } = useTheme();
    const context = useFontSize();
    const { increaseFontSize, decreaseFontSize, resetFontSize } = context;

    const [ScreenMode, setScreenMode] = useState(true);

    const handleScreenMode = () => {
        setScreenMode(!ScreenMode);
    }

    useEffect(() => {
        const content = document.getElementById('main-content');

        if (content) {
            content.style.filter = ScreenMode ? 'none' : 'invert(100%)';
        }
    }, [ScreenMode])

    // const { increaseFontSize, decreaseFontSize, resetFontSize } = context;

    return (
        <div>
            {/* <FontSizeProvider> */}
            <div className="fab-wrapper">
                <input id="fabCheckbox" type="checkbox" className="fab-checkbox" />
                <label className="fab" for="fabCheckbox">
                    <span className="fab-dots fab-dots-1"></span>
                    <span className="fab-dots fab-dots-2"></span>
                    <span className="fab-dots fab-dots-3"></span>
                </label>
                <div className="fab-wheel">
                    <button className="fab-action fab-action-1">
                        <img
                            src={increaseIcon}
                            alt="Increase font size"
                            onClick={increaseFontSize}
                            style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                        />
                    </button>
                    <button className="fab-action fab-action-2">
                        <img
                            src={resetIcon}
                            alt="Reset font size"
                            onClick={resetFontSize}
                            style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                        />
                    </button>
                    <button className="fab-action fab-action-3">
                        <img
                            src={decreaseIcon}
                            alt="Decrease font size"
                            onClick={decreaseFontSize}
                            style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                        />
                    </button>
                    <Link className="fab-action fab-action-4" to={"/screenreaderaccess"}>
                        SRA
                    </Link>
                    <button className="fab-action fab-action-5" onClick={handleScreenMode}>
                        <InvertColorsIcon style={ScreenMode == false ? { display: 'block' } : { display: 'none' }} />
                        <ContrastIcon style={ScreenMode == true ? { display: 'block' } : { display: 'none' }} />
                    </button>
                    <button className="fab-action fab-action-6" onClick={toggleTheme}>
                        <LightModeIcon style={isNightMode == false ? { display: 'block' } : { display: 'none' }} />
                        <DarkModeIcon style={isNightMode == true ? { display: 'block' } : { display: 'none' }} />
                    </button>
                </div>
            </div>
          {/* </FontSizeProvider> */}
        </div>
    )
}

export default SideMenuButton
