import './HomeSlider.css';

import React, { useEffect, useRef } from 'react'
// import bannerImage1 from '../../../images/v1.mp4'
// import bannerImage2 from '../../../images/v2.mp4'
// import bannerImage3 from '../../../images/v3.mp4'
// import bannerImage4 from '../../../images/v4.mp4'
// import bannerImage5 from '../../../images/v5.mp4'
// import bannerImage6 from '../../../images/v6.mp4'
// import bannerImage0 from '../../../Images_1/Home/SliderImages/germi.mp4'
// import bannerImage1 from '../../../Images_1/Home/SliderImages/ntm.mp4'
// import bannerImage2 from '../../../Images_1/Home/SliderImages/rm.mp4'
// import bannerImage3 from '../../../Images_1/Home/SliderImages/pm.mp4'
// import bannerImage4 from '../../../Images_1/Home/SliderImages/em.mp4'
// import bannerImage5 from '../../../Images_1/Home/SliderImages/prm.mp4'
import skipicon from "../../../Images_1/CommonSection/Header/skipicon.png"
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore from 'swiper';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
// import "./HomeSwiperCustom.css"
// import svg_dots from "../../../images/svg-home-video-slider.svg"

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Link } from 'react-router-dom';

function playAnimation() {

  const sliderLink = 'https://germi-public.s3.ap-south-1.amazonaws.com/Slider/';

  // const animateButton1 = document.getElementById('buttonprev');
  // const animateButton2 = document.getElementById('buttonnext');

  // animateButton2.addEventListener('click', function () {
  //   const elementToAnimate = document.querySelector('.image-translateCustomSlider2').style.display = "none"; // Adjust this selector as per your swiper library
  //   elementToAnimate.classList.add('animated');

  //   // Remove the animation class after the animation completes
  //   elementToAnimate.addEventListener('animationend', function () {
  //     elementToAnimate.classList.remove('animated');
  //   });
  // });



  // animateButton1.addEventListener('click', function () {
  //   const elementToAnimate = document.querySelector('.image-translateCustomSlider2'); // Adjust this selector as per your swiper library
  //   elementToAnimate.classList.add('animated');

  //   // Remove the animation class after the animation completes
  //   elementToAnimate.addEventListener('animationend', function () {
  //     elementToAnimate.classList.remove('animated');
  //   });
  // });
}

const CustomPrevButton = ({ onClick }) => {
  return (
    <button id='buttonprev' onClick={onClick} className="custom-prev-button">
      <KeyboardDoubleArrowLeftIcon className='icons-skew-in-slider-for-button' />
    </button>
  );
};

const CustomNextButton = ({ onClick }) => {
  return (
    <button id='buttonnext' onClick={onClick} className="custom-next-button">
      <KeyboardDoubleArrowRightIcon className='icons-skew-in-slider-for-button' />
    </button>
  );
};

// Function to check if an element is in the viewport
function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function startAnimationOnScroll() {
  // const hr1 = document.getElementById("id1");
  // const hr2 = document.getElementById(id2);
  const title1 = document.getElementById("id1");
  // console.log(title1);
  // const title2 = document.getElementById(t2);
  setTimeout((title1) => {
    if (title1 && isInViewport(title1)) {
      // console.log('h1')
      title1.classList.add('image-translateCustomSlider');
    }
    else {
      // console.log('shit')
    }
  }, 5000);

  // if (title2 && isInViewport(title2)) {
  //   title2.classList.add('title-animation-about-us');
  // }
  // if (hr1 && isInViewport(hr1)) {
  //   hr1.classList.add('animate-hr-tag-about-us');
  // }
  // if (hr2 && isInViewport(hr2)) {
  //   hr2.classList.add('animate-hr-tag-about-us');
  // }
}

function removeAnimation(toSelect) {
  // onSlideChangeTransitionStart = {(swiper) => {
  const slide = document.querySelector(toSelect);
  const head1 = slide && slide.getElementsByClassName('custom-slider-changing-text')[0];
  const head2 = slide && slide.getElementsByClassName('custom-slider-changing-paragraph')[0];
  const readMore = slide && slide.getElementsByClassName('custom-slider-changing-button')[0];
  head1 && head1.classList.remove('image-translateCustomSlider');
  head2 && head2.classList.remove('image-translateCustomSlider1');
  readMore && readMore.classList.remove('image-translateCustomSlider2');
  // }}
}

const MySwiper = () => {
  
  SwiperCore.use([Navigation]);



  return (

    <Swiper
      className='custome-swiper-class'
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      // spaceBetween={10}
      slidesPerView={1}
      navigation={{
        prevEl: '.custom-prev-button',
        nextEl: '.custom-next-button'
      }}

      autoplay={{
        delay: 8000,
        disableOnInteraction: false,
      }}

      loop={true}



      onInit={(swiper) => {
        const currSlide = document.querySelector('.swiper-slide-active');
        const head1 = currSlide && currSlide.getElementsByClassName('custom-slider-changing-text')[0];
        const head2 = currSlide && currSlide.getElementsByClassName('custom-slider-changing-paragraph')[0];
        const readMore = currSlide && currSlide.getElementsByClassName('custom-slider-changing-button')[0];
        head1 && head1.classList.add('image-translateCustomSlider');
        head2 && head2.classList.add('image-translateCustomSlider1');
        readMore && readMore.classList.add('image-translateCustomSlider2');
      }}

      onTransitionStart={(swiper) => {
        // console.log("hello")
        // startAnimationOnScroll();
        const currSlide = document.querySelector('.swiper-slide-active');
        const head1 = currSlide && currSlide.getElementsByClassName('custom-slider-changing-text')[0];
        const head2 = currSlide && currSlide.getElementsByClassName('custom-slider-changing-paragraph')[0];
        const readMore = currSlide && currSlide.getElementsByClassName('custom-slider-changing-button')[0];
        head1 && head1.classList.add('image-translateCustomSlider');
        head2 && head2.classList.add('image-translateCustomSlider1');
        readMore && readMore.classList.add('image-translateCustomSlider2');
      }
      }

      onSlideChangeTransitionEnd={(swiper) => {
        removeAnimation('.swiper-slide-prev');
        removeAnimation('.swiper-slide-next');
        // const currSlide = document.querySelector('.swiper-slide-prev');
        // const head1 = currSlide && currSlide.getElementsByClassName('custom-slider-changing-text')[0];
        // const head2 = currSlide && currSlide.getElementsByClassName('custom-slider-changing-paragraph')[0];
        // const readMore = currSlide && currSlide.getElementsByClassName('custom-slider-changing-button')[0];
        // // setTimeout(() => {
        //   head1 && head1.classList.remove('image-translateCustomSlider');
        //   head2 && head2.classList.remove('image-translateCustomSlider1');
        //   readMore && readMore.classList.remove('image-translateCustomSlider2');

        // // }, 1500)

      }}
    >

      <SwiperSlide className='swiper-slide-overflow'>
        <div className='theAlign'>
        {/* <Link className='linkheaderright1' onClick={() =>{ 
          topWrapperRef1.current.scrollIntoView({ behavior: 'smooth' })

          // Set focus on the div
          topWrapperRef1.current.focus()
          
          }}>{" > Skip to main Content"}</Link> */}
          <div className="slider-content" style={{
            "width": "100%!important",
            "height": "100%!important"
          }}>
            <video className="main-slider-image" loop muted autoPlay playsInline>
              {/* <source src={bannerImage0} type="video/mp4" /> */}
              <source src={'https://germi-public.s3.ap-south-1.amazonaws.com/Slider/germi.mp4'} type="video/mp4" />
            </video>
            <div className='col-md-6 custom-swiper-div-with-black-bg'></div>
            <div className='col-md-6 custom-swiper-div-with-black'></div>
            <div className='hs-content-div'>
              <h1 className='custom-slider-changing-text  large-heading-fonts'>GERMI</h1>
              <h6 className='custom-slider-changing-paragraph normal-text'>Gujarat Energy Research & Management Institute (GERMI) is a centre of excellence in the energy sector, promoted by Gujarat State Petroleum Corporation Limited (GSPC).</h6>
              <Link className="hs-button-link" to="aboutus">
                <button className="custom-slider-changing-button skew-button normal-text">
                  Read More<div className=' icons-in-button-slider-animated'> <KeyboardDoubleArrowRightIcon className='icons-skew-in-slider' /></div>
                </button>
              </Link>
            </div>

            <div className='col-md-6 custom-swiper-div-with-black'>  </div>
            {/* <div className='col-md-6 custom-swiper-div-with-black-bg3'>  </div> */}
            {/* <img className='svg-home-main-slider' src={svg_dots}/>
<img className='svg-home-main-slider1' src={svg_dots}/> */}
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className='swiper-slide-overflow'>
        <div className='theAlign'>
        
          <div className="slider-content" style={{
            "width": "100%!important",
            "height": "100%!important"
          }}>
            <video className="main-slider-image" loop muted autoPlay playsInline>
              {/* <source src={bannerImage1} type="video/mp4" /> */}
              <source src={'https://germi-public.s3.ap-south-1.amazonaws.com/Slider/ntm.mp4'} type="video/mp4" />
            </video>
            <div className='col-md-6 custom-swiper-div-with-black-bg'>  </div>
            <div className='col-md-6 custom-swiper-div-with-black'>  </div>
            <div className='hs-content-div'>
              <h1 id="id1" className='custom-slider-changing-text large-heading-fonts'>New Technology Management</h1>
              <h6 className='custom-slider-changing-paragraph normal-text'>Emerging technologies are pivotal in moulding the future of renewable energy endeavors, enhancing their efficiency, cost-effectiveness and scalability.</h6>
              <Link className="hs-button-link" to="departments/new-technology-management/5/0">
                <button className="custom-slider-changing-button skew-button normal-text">
                  Read More<div className=' icons-in-button-slider-animated'> <KeyboardDoubleArrowRightIcon className='icons-skew-in-slider' /></div>
                </button>
              </Link>
            </div>

            <div className='col-md-6 custom-swiper-div-with-black'>  </div>
            {/* <div className='col-md-6 custom-swiper-div-with-black-bg3'>  </div> */}
            {/* <img className='svg-home-main-slider' src={svg_dots}/>
<img className='svg-home-main-slider1' src={svg_dots}/> */}
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className='swiper-slide-overflow'>
        <div className='theAlign'>
        
          <div className="slider-content" style={{
            "width": "100%!important",
            "height": "100%!important"
          }}>
            <video className="main-slider-image" loop muted autoPlay playsInline>
              {/* <source src={bannerImage2} type="video/mp4" /> */}
              <source src={'https://germi-public.s3.ap-south-1.amazonaws.com/Slider/rm.mp4'} type="video/mp4" />
            </video>
            <div className='col-md-6 custom-swiper-div-with-black-bg'>  </div>
            <div className='col-md-6 custom-swiper-div-with-black'>  </div>
            <div className='hs-content-div'>
              <h1 className='custom-slider-changing-text large-heading-fonts'>Renewables Management</h1>
              <h6 className='custom-slider-changing-paragraph normal-text'>We are transitioning to renewable energy to reduce our carbon footprint and protect the environment. As part of this effort, we are actively exploring new opportunities to replace fossil fuels in our operations.</h6>
              {/* <h6 className='custom-slider-changing-paragraph normal-text'>*We are transitioning to renewable energy to reduce our carbon footprint and protect the environment. In doing so, we are actively exploring new opportunities to replace fossil fuels in our operations.</h6> */}
              {/* <h6 className='custom-slider-changing-paragraph normal-text'>*We're transitioning to renewables to cut down the carbon footprints and protect the environment, exploring new opportunities to replace the fossil fuels during our operations.</h6> */}
              <Link className="hs-button-link" to="departments/renewable-management/5/0">
                <button className="custom-slider-changing-button skew-button normal-text">
                  Read More<div className=' icons-in-button-slider-animated'> <KeyboardDoubleArrowRightIcon className='icons-skew-in-slider' /></div>
                </button>
              </Link>
            </div>

            <div className='col-md-6 custom-swiper-div-with-black'>  </div>
            {/* <div className='col-md-6 custom-swiper-div-with-black-bg3'>  </div> */}
            {/* <img className='svg-home-main-slider' src={svg_dots}/>
<img className='svg-home-main-slider1' src={svg_dots}/> */}
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className='swiper-slide-overflow'>
        <div className='theAlign'>
        
          <div className="slider-content" style={{
            "width": "100%!important",
            "height": "100%!important"
          }}>
            <video className="main-slider-image" loop muted autoPlay playsInline>
              {/* <source src={bannerImage3} type="video/mp4" /> */}
              <source src={'https://germi-public.s3.ap-south-1.amazonaws.com/Slider/pm.mp4'} type="video/mp4" />
            </video>
            <div className='col-md-6 custom-swiper-div-with-black-bg'>  </div>
            <div className='col-md-6 custom-swiper-div-with-black'>  </div>
            <div className='hs-content-div'>
              <h1 className='custom-slider-changing-text large-heading-fonts'>Petroleum Management</h1>
              <h6 className='custom-slider-changing-paragraph normal-text'>Advancing Petroleum sector with leading-edge Geological & Geophysical Analysis, innovative Software Development, and expert Consulting Services. Unleashing potential with precision.</h6>
              <Link className="hs-button-link" to="departments/petroleum-management/5/0">
                <button className="custom-slider-changing-button skew-button normal-text">
                  Read More
                  <div className=' icons-in-button-slider-animated'>
                    <KeyboardDoubleArrowRightIcon className='icons-skew-in-slider' />
                  </div>
                </button>
              </Link>
            </div>

            <div className='col-md-6 custom-swiper-div-with-black'>  </div>
            {/* <div className='col-md-6 custom-swiper-div-with-black-bg3'>  </div> */}
            {/* <img className='svg-home-main-slider' src={svg_dots}/>
<img className='svg-home-main-slider1' src={svg_dots}/> */}
          </div>
        </div>
      </SwiperSlide>

   


      <SwiperSlide className='swiper-slide-overflow'>
        <div className='theAlign'>
       
          <div className="slider-content" style={{
            "width": "100%!important",
            "height": "100%!important"
          }}>
            <video className="main-slider-image" loop muted autoPlay playsInline>
              {/* <source src={bannerImage5} type="video/mp4" /> */}
              <source src={'https://germi-public.s3.ap-south-1.amazonaws.com/Slider/prm.mp4'} type="video/mp4" />
            </video>
            <div className='hs-content-div'>
              <h1 className='custom-slider-changing-text large-heading-fonts'>Petroleum Research Management </h1>
              <h6 className='custom-slider-changing-paragraph normal-text'>Hydrocarbon exploration, alternate fuels, energy mix: methane-hydrogen, compressed biogas, qualitative water resource assessment and management, CO2 sequestration, industrial support etc.</h6>
              <Link className="hs-button-link" to="departments/petroleum-research-management/5/0">
                <button className="custom-slider-changing-button skew-button normal-text">
                  Read More<div className=' icons-in-button-slider-animated'> <KeyboardDoubleArrowRightIcon className='icons-skew-in-slider' /></div>
                </button>
              </Link>

            </div>
            <div className='col-md-6 custom-swiper-div-with-black-bg'>  </div>
            <div className='col-md-6 custom-swiper-div-with-black'>  </div>
            {/* <img className='svg-home-main-slider' src={svg_dots}/>
<img className='svg-home-main-slider1' src={svg_dots}/> */}
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className='swiper-slide-overflow'>
        <div className='theAlign'>
        
          <div className="slider-content" style={{
            "width": "100%!important",
            "height": "100%!important"
          }}>
            <video className="main-slider-image" loop muted autoPlay playsInline>
              {/* <source src={bannerImage4} type="video/mp4" /> */}
              <source src={'https://germi-public.s3.ap-south-1.amazonaws.com/Slider/em.mp4'} type="video/mp4" />
            </video>
            <div className='col-md-6 custom-swiper-div-with-black-bg'>  </div>
            <div className='col-md-6 custom-swiper-div-with-black'>  </div>
            <div className='hs-content-div'>
              <h1 className='custom-slider-changing-text large-heading-fonts'>Environment Management</h1>
              <h6 className='custom-slider-changing-paragraph normal-text'>The Environment Management Department conducts research on national and global environmental concerns, focusing on pollution, energy efficiency, renewable energy, and waste-to-energy conversion.
                {/* It addresses industry challenges with innovative solutions, aligning with India's environmental commitments. */}
              </h6>
              <Link className="hs-button-link" to="departments/environment-management/5/0">
                <button className="custom-slider-changing-button skew-button normal-text">
                  Read More<div className=' icons-in-button-slider-animated'> <KeyboardDoubleArrowRightIcon className='icons-skew-in-slider' /></div>
                </button>
              </Link>
            </div>

            <div className='col-md-6 custom-swiper-div-with-black'>  </div>
            <div className='col-md-6 custom-swiper-div-with-black'>  </div>
            {/* <img className='svg-home-main-slider' src={svg_dots}/>
<img className='svg-home-main-slider1' src={svg_dots}/> */}
          </div>
        </div>
      </SwiperSlide>
      {/* <SwiperSlide className='swiper-slide-overflow'>
                         <div className='theAlign'>
        <div className="slider-content" style={{
        "width": "100%!important",
        "height": "100%!important"
      }}>
            <video className="main-slider-image" loop muted autoPlay>
                <source src={bannerImage6} type="video/mp4" />
            </video>
            <div className='custom-swiper-div-with-black-bg'>  </div>
            <div className='col-md-6 custom-swiper-div-with-black'>  </div>
            <h1 className='custom-slider-changing-text image-translateCustomSlider'>HR & Admin Finance</h1>
        <h6 className='custom-slider-changing-paragraph image-translateCustomSlider1'>Human resources (HR) is the division of a business responsible for finding, recruiting, screening, and training job applicants. HR departments also handle employee compensation, benefits, and terminations.</h6>
        <button className="custom-slider-changing-button skew-button image-translateCustomSlider2">
Read More<div className=' icons-in-button-slider-animated'> <KeyboardDoubleArrowRightIcon className='icons-skew-in-slider'/></div>
      </button>
      <div className='col-md-6 custom-swiper-div-with-black'>  </div>
      <div className='col-md-6 custom-swiper-div-with-black'>  </div>
      
        </div>
    </div>
                    </SwiperSlide> */}


    </Swiper>

  )
}

const HomeSlider = (props) => {
  const updatesData = props.data
  const topWrapperRef1 = props.topWrapperRef1
  console.log("updatesData")
  console.log(topWrapperRef1)
  return (
    <div className="App">

      <section>
        {/* <MyComponent /> */}
        <div className="pbmit-col-stretched-right">
          <div
            className="swiper-slider"
            data-autoplay="true"
            data-loop="true"
            data-dots="false"
            data-arrows="false"
            data-columns="4"
            data-margin="30"
            data-effect="slide"
          >

            <CustomPrevButton />
            <Link className='linkheaderright1' onClick={() =>{ 
        topWrapperRef1&&  topWrapperRef1.current.scrollIntoView({ behavior: 'smooth' })

          // Set focus on the div
          topWrapperRef1 && topWrapperRef1.current.focus()
          
          }}>{" > Skip to main Content"}</Link>
            <MySwiper  />
            <CustomNextButton />

            {/* </div> */}
          </div>
        </div>
        {updatesData && updatesData.lenght!=0?
        <div className="announcement-Heading" >

          <div className='marquee-container'>
            <div className='marquee'>
              {updatesData && updatesData.map((data) =>
              (
                <a className="headingtitlemq normal-text" href={data.link}>{data.title}&nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;  &nbsp;&nbsp;</a>
              ))}
            </div>
          </div>

        </div>:<></>}

      </section>
    </div>
  );
};

export default HomeSlider;
