import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicRequest } from "../../../constants";

export const sendfeedbackcall = createAsyncThunk(
  "addannnouncement",
  async (data) => {
    // console.log("here")
    const json=JSON.stringify(data)
    const res = await publicRequest.post("/addfeedback", json);
    if (res) {
       console.log(res);
      // if (res.data.status) {
      //   alert(res.data.message)
      // } 
      return res.data
    }

  }
);



const sendfeedbackSlice = createSlice({
  name: "addannnouncement",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendfeedbackcall.fulfilled, (state, action) => {
      return action.payload
    })
  }
});

export default sendfeedbackSlice.reducer;